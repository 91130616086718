import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik } from 'formik'

import BasePage from '../../components/BasePage/BasePage'
import * as Styled from './EditPodcastSupportPage.styled'
import useFetch from '../../hooks/useFetch'
import {
  FullSupportInformation,
  RecipientStatusDTO,
  SupportTier,
} from '../../types'
import {
  createSupportTier,
  deleteSupportTier,
  getRecipientStatus,
  getSupportInformationByPodcastId,
  updateSupportDescription,
  updateSupportTier,
  uploadArticleImage,
} from '../../services/api'
import Button from '../../components/Button/Button'
import RichTextEditorField from '../../components/Fields/RichTextEditorField/RichTextEditorField'
import {
  MessageWrapper,
  PageWrapper,
} from '../podcastSupport/PodcastSupport.styled'
import { Snackbar } from '@material-ui/core'
import TextFieldField from '../../components/Fields/TextFieldField/TextFieldField'
import NumberFormatField from '../../components/Fields/NumberFormatField/NumberFormatField'
import Dialog from '../../components/Dialog/Dialog'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Colors } from 'styles'
import { useGoToRoute } from 'Routes/RouteAux'
import { getCreatorProfilePath } from 'Routes/RouteNames'

const tiersValidationSchema = Yup.object().shape({
  title: Yup.string().required('Campo obrigatório'),
  description: Yup.string(),
  value: Yup.string()
    .required('Campo obrigatório')
    .test(
      'minValue',
      'O valor deve ser maior que zero',
      (value) => Number(value) > 0,
    )
    .test(
      'maxValue',
      'O valor máximo é R$2500,00',
      (value) => Number(value) <= 2500,
    ),
})

interface FormType {
  title: string
  value: string
  description: string
  startEditable?: boolean
}

interface EditPodcastSupportPageProps {
  signOut: () => Promise<void>
}

const EditPodcastSupportPage: React.FC<EditPodcastSupportPageProps> = ({
  signOut,
}) => {
  const { podcastId } = useParams<{ podcastId: string }>()
  const [errorOnProccess, setErrorOnProccess] = useState<string>()
  const [successMessage, setSuccessMessage] = useState<string>()
  const [waitingForResponse, setWaitingForResponse] = useState(false)
  const [editingTierId, setEditingTierId] = useState<string>()
  const [editingDescription, setEditingDescription] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [supportTierToDelete, setSupportTierToDelete] = useState<string>()
  const [supportTiers, setSupportTiers] = useState<SupportTier[]>()
  const [isRecipientModalOpen, setIsRecipientModalOpen] = useState(false)
  const goToRoute = useGoToRoute()

  const { isLoading, data, error, fetchData } =
    useFetch<FullSupportInformation>(
      useCallback(() => {
        return getSupportInformationByPodcastId(podcastId)
      }, [podcastId]),
    )

  const {
    isLoading: isLoadingRecipientStatus,
    data: recipientStatusData,
    error: recipientStatusError,
    fetchData: fetchRecipientStatus,
  } = useFetch<RecipientStatusDTO>(
    useCallback(() => {
      return getRecipientStatus(podcastId)
    }, [podcastId]),
    false,
  )

  const saveDescription = async (supportDescription: string): Promise<void> => {
    setWaitingForResponse(true)
    const res = await updateSupportDescription(podcastId, supportDescription)
    setWaitingForResponse(false)
    if (res.error) {
      setErrorOnProccess(res.error.message)
    }
  }

  const saveSupportTier = async (
    supportTierId: string,
    supportTier: SupportTier,
  ): Promise<void> => {
    setWaitingForResponse(true)
    const res = await updateSupportTier(podcastId, supportTierId, supportTier)
    setWaitingForResponse(false)
    if (res.error) {
      setErrorOnProccess(res.error.message)
    } else {
      setEditingTierId(null)
      setSuccessMessage('Nível de apoio atualizado com sucesso!')
      fetchData()
    }
  }

  const uploadFile = async (file: File) => {
    const uploadFileResponse = {
      error: null,
      imageUrl: null,
    }
    const res = await uploadArticleImage(podcastId, file)

    if (res.error) {
      uploadFileResponse.error =
        'Erro ao fazer upload da imagem. Tente novamente ou entre em contato com o suporte'
    } else {
      uploadFileResponse.imageUrl = res.data
    }
    return uploadFileResponse
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleCloseRecipientModal = () => {
    goToRoute(getCreatorProfilePath(podcastId))
  }

  const onDeleteSupportTier = async (supportTierId: string): Promise<void> => {
    if (supportTierToDelete) {
      const res = await deleteSupportTier(podcastId, supportTierId)
      if (res.error) {
        setErrorOnProccess(res.error.message)
      } else {
        setSuccessMessage('Nível de apoio removido com sucesso!')
      }
    }
  }

  const onAddSupportTier = async (): Promise<void> => {
    setSupportTiers([
      {
        podcastId,
        id: '',
        value: '0.00',
        title: '',
        description: '',
      } as SupportTier,
      ...data.supportTiers,
    ])
  }

  useEffect(() => {
    if (data?.supportTiers) {
      setSupportTiers(data.supportTiers)
    }
  }, [data])

  useEffect(() => {
    if (!supportTiers || supportTiers.length) return
    fetchRecipientStatus()
  }, [supportTiers, fetchRecipientStatus])

  // useEffect(() => {
  //   const recipientNotFound =
  //     recipientStatusError?.message === 'Recipient not found'
  //   const pendingRecipient = [
  //     'pending',
  //     'registration',
  //     'affiliation',
  //   ].includes(recipientStatusData?.status)

  //   if (recipientNotFound || pendingRecipient) {
  //     setIsRecipientModalOpen(true)
  //   }
  // }, [recipientStatusData, recipientStatusError])

  if (isLoading || isLoadingRecipientStatus) {
    return (
      <BasePage signOut={signOut} showNavigationBar>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>Carregando...</Styled.MessageWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  if (error) {
    return (
      <BasePage signOut={signOut} isDark showNavigationBar>
        <PageWrapper>
          <MessageWrapper>Ops, parece que tivemos um erro aqui.</MessageWrapper>
          <Button variant="contained" fontColor="black" onClick={fetchData}>
            Tentar novamente
          </Button>
        </PageWrapper>
      </BasePage>
    )
  }

  return (
    <BasePage signOut={signOut} showNavigationBar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!errorOnProccess}
        autoHideDuration={10000}
        onClose={() => setErrorOnProccess(undefined)}
        message={`Ocorreu um erro inesperado. Tente novamente ou entre em contato com o suporte`}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!successMessage}
        autoHideDuration={10000}
        onClose={() => setSuccessMessage(undefined)}
        message={successMessage}
      />

      <Dialog
        isDialogOpen={isModalOpen}
        handleCloseDialog={handleCloseModal}
        dialogText="tem certeza que deseja apagar este tier?"
        secondaryDialogText="essa ação não pode ser desfeita  "
        dialogActionButtonText="apagar"
        onClickDialogActionButton={async () => {
          await onDeleteSupportTier(supportTierToDelete)
          handleCloseModal()
          await fetchData()
        }}
        noActionText="cancelar"
      />

      <Dialog
        isDialogOpen={isRecipientModalOpen}
        handleCloseDialog={handleCloseRecipientModal}
        dialogText="Você precisa finalizar o seu cadastro para gerenciar seus apoios"
        dialogActionButtonText="ok"
        onClickDialogActionButton={handleCloseRecipientModal}
      />

      <Styled.PageWrapper>
        <Styled.TitleAndLinkWrapper>
          <Styled.PageTitle>Níveis de Apoios Recorrentes</Styled.PageTitle>

          <Styled.ButtonWrapper>
            <ButtonShadow
              onPress={() => onAddSupportTier()}
              label="Adicionar Novo Nível"
              leftIcon={
                <FontAwesomeIcon
                  icon={faPlus}
                  color={Colors.BRAND_SECONDARY}
                  name="plus-icon"
                />
              }
            />
          </Styled.ButtonWrapper>
        </Styled.TitleAndLinkWrapper>

        <Styled.ItemGrid>
          <Formik
            initialValues={{
              supportDescription: data?.supportSummary?.supportDescription,
            }}
            onSubmit={async (values) => {
              await saveDescription(values.supportDescription)
              setEditingDescription(false)
            }}
          >
            {({ handleSubmit, setFieldValue }) => (
              <Styled.Item>
                <Styled.ItemWrapper>
                  <Styled.ItemHeader>Descrição Geral</Styled.ItemHeader>

                  <Styled.ItemBody>
                    <RichTextEditorField
                      name="supportDescription"
                      placeholder="Descrição da página de apoio"
                      toolbarOptions={[
                        'bold',
                        'italic',
                        'underline',
                        'code',
                        'heading',
                        'block-quote',
                        'numbered-list',
                        'bulleted-list',
                        'hyperlink',
                        'image',
                      ]}
                      uploadFile={uploadFile}
                      onInsertImage={(imageUrl) =>
                        setFieldValue('image', imageUrl)
                      }
                      noBorder
                      readonly={!editingDescription}
                    />
                  </Styled.ItemBody>
                </Styled.ItemWrapper>

                <Styled.ItemButtons>
                  {!editingDescription && (
                    <Button
                      buttonColor="white"
                      buttonColorOnHover="#919191"
                      fontColor="black"
                      borderColor="black"
                      onClick={() => setEditingDescription(true)}
                    >
                      Editar
                    </Button>
                  )}

                  {editingDescription && (
                    <Button
                      buttonColor="white"
                      buttonColorOnHover="#919191"
                      fontColor="black"
                      borderColor="black"
                      onClick={() => handleSubmit()}
                      disabled={waitingForResponse}
                    >
                      Salvar {waitingForResponse && <Styled.LoadingCircle />}
                    </Button>
                  )}
                </Styled.ItemButtons>
              </Styled.Item>
            )}
          </Formik>

          {!supportTiers ||
            (supportTiers?.length <= 0 && (
              <Styled.EmptyStateMesage>
                Você ainda não possui nenhum nível de apoio configurado.
                <br />
                <u onClick={() => onAddSupportTier()}>
                  Clique aqui para criar um.
                </u>
              </Styled.EmptyStateMesage>
            ))}

          {supportTiers?.length > 0 &&
            supportTiers.map((supportTier) => (
              <Formik
                initialValues={{
                  description: supportTier.description,
                  title: supportTier.title,
                  value: supportTier.value,
                  id: supportTier.id,
                  podcastId: supportTier.podcastId,
                }}
                onSubmit={async (values) => {
                  if (supportTier.id) {
                    await saveSupportTier(supportTier.id, values)
                  } else {
                    const res = await createSupportTier(podcastId, values)
                    if (res.error) {
                      setErrorOnProccess(res.error.message)
                    } else {
                      setSuccessMessage(
                        'Você acabou de criar um novo nível de apoio, clique no botão Editar para atualizar suas informações.',
                      )
                      fetchData()
                    }
                  }
                }}
                validationSchema={tiersValidationSchema}
                enableReinitialize
              >
                {({ handleSubmit, values, errors }) => (
                  <Styled.Item>
                    <Styled.ItemWrapper isDark>
                      {editingTierId !== supportTier.id && (
                        <Styled.TierHeader>
                          <Styled.ItemHeader isDark>
                            {supportTier.title}
                          </Styled.ItemHeader>
                          <Styled.ItemValue isDark>
                            R$ {parseInt(supportTier.value)}
                          </Styled.ItemValue>
                        </Styled.TierHeader>
                      )}

                      {editingTierId === supportTier.id && (
                        <Styled.ItemHeader isDark>
                          <TextFieldField
                            name="title"
                            variant="outlined"
                            placeholder="Nome"
                            label="Nome"
                            value={values.title}
                            error={!!errors?.title}
                            helperText={errors?.title}
                            style={{
                              backgroundColor: 'white',
                              borderRadius: 20,
                            }}
                            darkMode
                            labelColor={Colors.WHITE}
                          />
                        </Styled.ItemHeader>
                      )}

                      {editingTierId === supportTier.id && (
                        <Styled.ItemSubHeader isDark>
                          <NumberFormatField
                            decimalSeparator=","
                            thousandSeparator="."
                            allowNegative={false}
                            prefix="R$ "
                            decimalScale={2}
                            fixedDecimalScale={true}
                            hintText="Valor do apoio"
                            placeholder="Valor do apoio"
                            name="value"
                            value={values.value}
                            error={!!errors?.value}
                            helperText={errors?.value}
                            label="Valor"
                          />
                        </Styled.ItemSubHeader>
                      )}

                      <Styled.ItemBody>
                        {editingTierId === supportTier.id && (
                          <TextFieldField
                            name="description"
                            placeholder="Descrição"
                            variant="outlined"
                            label="Descrição"
                            value={values.description}
                            error={!!errors?.description}
                            helperText={errors?.description}
                            multiline={true}
                            style={{
                              backgroundColor: 'white',
                              borderRadius: 20,
                            }}
                            darkMode
                            labelColor={Colors.WHITE}
                          />
                        )}

                        {editingTierId !== supportTier.id && (
                          <>
                            <Styled.DescriptionTitle>
                              <b>Descrição:</b>
                            </Styled.DescriptionTitle>
                            <Styled.Description>
                              <br />
                              {supportTier.description}
                            </Styled.Description>
                          </>
                        )}
                      </Styled.ItemBody>
                    </Styled.ItemWrapper>

                    <Styled.ItemButtons>
                      {editingTierId !== supportTier.id && (
                        <Button
                          buttonColor="white"
                          buttonColorOnHover="#919191"
                          fontColor="black"
                          borderColor="black"
                          onClick={() => setEditingTierId(supportTier.id)}
                        >
                          Editar
                        </Button>
                      )}

                      {editingTierId === supportTier.id && (
                        <Button
                          buttonColor="white"
                          buttonColorOnHover="#919191"
                          fontColor="black"
                          borderColor="black"
                          onClick={() => handleSubmit()}
                        >
                          Salvar{' '}
                          {waitingForResponse && <Styled.LoadingCircle />}
                        </Button>
                      )}

                      <Button
                        buttonColor="white"
                        buttonColorOnHover="#910101"
                        fontColor="#FF0000"
                        borderColor="#FF0000"
                        onClick={() => {
                          setSupportTierToDelete(supportTier.id)
                          setIsModalOpen(true)
                        }}
                      >
                        Apagar
                      </Button>
                    </Styled.ItemButtons>
                  </Styled.Item>
                )}
              </Formik>
            ))}
        </Styled.ItemGrid>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default EditPodcastSupportPage
