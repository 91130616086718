import React, { Fragment, useState } from 'react'
import * as Yup from 'yup'
import { MenuItem, Tooltip } from '@material-ui/core'
import { Formik, FormikValues } from 'formik'
import * as Styled from './ArticleForm.styled'
import { LastAutoSave } from './ArticleForm.styled'
import TextFieldField from '../Fields/TextFieldField/TextFieldField'
import RichTextEditorField from '../Fields/RichTextEditorField/RichTextEditorField'
import SelectField from '../Fields/SelectField/SelectField'
import Info from '@material-ui/icons/Info'
import { uploadArticleImage } from '../../services/api'
import { Typography } from 'styles'
import { debounce } from 'debounce'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'

interface FormValues {
  title: string
  description: string
  body: string
  visibility: string
  stringNotifyOnPublish: string
  image?: string
}

const createArticleValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Campo obrigatório')
    .min(1, 'Campo obrigatório')
    .max(62, 'Tamanho máximo 62 caracteres'),
  description: Yup.string()
    .required('Campo obrigatório')
    .min(1, 'Campo obrigatório')
    .max(200, 'Tamanho máximo 200 caracteres'),
  body: Yup.string().required('Campo obrigatório').nullable(),
  visibility: Yup.string()
    .required('Campo obrigatório')
    .min(1, 'Campo obrigatório'),
  stringNotifyOnPublish: Yup.string()
    .required('Campo obrigatório')
    .min(1, 'Campo obrigatório'),
})

interface ArticleFormProps {
  initialValues: {
    title?: string
    description?: string
    body?: string
    visibility?: string
    notifyOnPublish?: boolean
    image?: string
  }
  onSubmit: (values: FormValues) => void | Promise<void>
  podcastId: string
  isEdit?: boolean
  onAutoSave?: (values: FormValues) => Promise<void>
  isPublished: boolean
}

const ArticleForm: React.FC<ArticleFormProps> = ({
  initialValues,
  onSubmit,
  isEdit,
  podcastId,
  onAutoSave,
  isPublished,
}) => {
  const [waitingForResponse, setWaitingForResponse] = useState(false)
  const [lastAutoSave, setLastAutoSave] = useState<string>()

  const uploadFile = async (file: File) => {
    const uploadFileResponse = {
      error: null,
      imageUrl: null,
    }
    const res = await uploadArticleImage(podcastId, file)

    if (res.error) {
      uploadFileResponse.error =
        'Erro ao fazer upload da imagem. Tente novamente ou entre em contato com o suporte'
    } else {
      uploadFileResponse.imageUrl = res.data
    }
    return uploadFileResponse
  }

  const notifyOnPublishLabel = isEdit ? (
    <Fragment>
      <Styled.TextLabel>
        Este texto {initialValues.notifyOnPublish ? '' : 'não '}foi enviado via
        e-mail na data da sua publicação.
      </Styled.TextLabel>
      <div>Esta configuração não se aplica para textos já publicados</div>
    </Fragment>
  ) : (
    <Styled.LabelWrapper>
      <Styled.InfoLabel>Deseja enviar também como newsletter?</Styled.InfoLabel>
      <Tooltip
        title={`A newsletter será enviada via email para os seus seguidores caso a visibilidade seja "Aberto para todos" ou apenas para os seus apoiadores caso a visibilidade seja "Exclusivo para apoiadores"`}
        placement="top"
      >
        <Info />
      </Tooltip>
    </Styled.LabelWrapper>
  )

  const onKeyUp = debounce((values: FormikValues) => {
    if (onAutoSave && !isPublished) {
      setWaitingForResponse(true)

      onAutoSave(values as FormValues).then(() => {
        setWaitingForResponse(false)
        setLastAutoSave(new Date().toLocaleTimeString())
      })
    }
  }, 1000)

  return (
    <Formik
      initialValues={{
        stringNotifyOnPublish: initialValues.notifyOnPublish ? 'true' : 'false',
        ...initialValues,
      }}
      onSubmit={async (values) => {
        setWaitingForResponse(true)
        await onSubmit(values as FormValues)
        setWaitingForResponse(false)
      }}
      validationSchema={createArticleValidationSchema}
    >
      {({
        handleSubmit,
        errors,
        touched,
        values,
        setFieldValue,
        handleBlur,
      }) => {
        return (
          <Styled.FlexForm>
            <Styled.InputWrapper>
              <TextFieldField
                placeholder="Insira o título do seu texto"
                name="title"
                variant="outlined"
                error={!!(touched.title && errors?.title)}
                helperText={touched.title && errors?.title}
                transparentBorder
                fullWidth
                onBlur={handleBlur}
                style={{
                  fontSize: 16,
                  fontWeight: 400,
                  letterSpacing: -Typography.LETTER_SPACING_1,
                }}
                value={values.title}
                onKeyUp={() => onKeyUp(values)}
                darkMode
                label="Título do texto"
              />
            </Styled.InputWrapper>

            <Styled.InputWrapper>
              <Styled.SubtitleInput
                placeholder="Insira aqui o substítulo"
                name="description"
                variant="outlined"
                multiline
                error={!!(touched.description && errors?.description)}
                helperText={touched.description && errors?.description}
                onBlur={handleBlur}
                transparentBorder
                fullWidth
                style={{ fontSize: 16 }}
                value={values.description}
                onKeyUp={() => onKeyUp(values)}
                darkMode
                label="Subtítulo"
              />
            </Styled.InputWrapper>

            <Styled.StyledLabel>Texto</Styled.StyledLabel>

            <RichTextEditorField
              name="body"
              placeholder="Aqui vai o conteúdo do seu texto"
              error={!!(touched.body && errors?.body)}
              helperText={touched.body && errors?.body}
              handleBlur={handleBlur}
              toolbarOptions={[
                'bold',
                'italic',
                'underline',
                'code',
                'heading',
                'block-quote',
                'numbered-list',
                'bulleted-list',
                'hyperlink',
                'image',
              ]}
              uploadFile={uploadFile}
              onInsertImage={(imageUrl) => setFieldValue('image', imageUrl)}
              onKeyUp={() => onKeyUp(values)}
              darkMode
            />
            <Styled.SubmitSelectWrapper>
              <SelectField
                name="visibility"
                label="Quem poderá ver esse texto?"
                variant="outlined"
                displayEmpty
                error={!!(touched.visibility && errors?.visibility)}
                helperText={touched.visibility && errors?.visibility}
                disabled={isEdit}
                darkMode
                fullWidth
                onBlur={handleBlur}
              >
                <MenuItem value="" disabled>
                  Escolha a visibilidade
                </MenuItem>
                <MenuItem value={'open'}>Aberto para todos</MenuItem>
                <MenuItem value={'supporters'}>
                  Exclusivo para apoiadores
                </MenuItem>
              </SelectField>
              <SelectField
                name="stringNotifyOnPublish"
                label={notifyOnPublishLabel}
                variant="outlined"
                displayEmpty
                error={!!(touched.visibility && errors?.visibility)}
                helperText={touched.visibility && errors?.visibility}
                disabled={isEdit}
                darkMode
                fullWidth
                onBlur={handleBlur}
              >
                <MenuItem value="" disabled>
                  Sim ou não
                </MenuItem>
                <MenuItem value={'true'}>Sim</MenuItem>
                <MenuItem value={'false'}>Não</MenuItem>
              </SelectField>
            </Styled.SubmitSelectWrapper>

            <Styled.SubmitButtonWrapper>
              <ButtonShadow
                onPress={() => handleSubmit()}
                disabled={waitingForResponse}
                label="Publicar"
              />

              <ButtonShadow
                variant="secondary"
                onPress={() => onKeyUp(values)}
                disabled={waitingForResponse}
                label="Salvar Rascunho"
              />
              {waitingForResponse && <Styled.LoadingCircle />}
              {lastAutoSave && (
                <LastAutoSave>Salvo às {lastAutoSave}</LastAutoSave>
              )}
            </Styled.SubmitButtonWrapper>
          </Styled.FlexForm>
        )
      }}
    </Formik>
  )
}

export default ArticleForm
