import React from 'react'
import { useField } from 'formik'
import { FormHelperText, Tooltip, Typography } from '@material-ui/core'
import { NumberFormatProps } from 'react-number-format'
import * as Styled from './NumberFormat.styled'
import { Help } from '@material-ui/icons'
import { Colors } from 'styles'

interface NumberFormatFieldProps extends NumberFormatProps {
  name: string
  hasEmptyValue?: boolean
  label?: string
  infoTooltip?: string
  error?: boolean
  helperText?: string
  fullWidth?: boolean
  darkMode?: boolean
  /** @default true */
  outline?: boolean
}

const NumberFormatField: React.FC<NumberFormatFieldProps> = ({
  name,
  label,
  value,
  error,
  helperText,
  hasEmptyValue,
  fullWidth,
  darkMode,
  outline = true,
  infoTooltip,
  ...props
}) => {
  const [_field, meta, helpers] = useField(name)

  return (
    <Styled.Wrapper fullWidth={fullWidth}>
      {label && (
        <Styled.LabelContainer darkMode={darkMode}>
          <Styled.StyledLabel htmlFor={name} darkMode={darkMode}>
            {label}
          </Styled.StyledLabel>
          {infoTooltip && (
            <Tooltip
              title={
                <Typography style={{ fontSize: '0.8rem' }}>
                  {infoTooltip}
                </Typography>
              }
              arrow
              interactive
            >
              <Help fontSize="small" style={{ color: Colors.GREY[400] }} />
            </Tooltip>
          )}
        </Styled.LabelContainer>
      )}
      <Styled.StyledNumberFormat
        defaultValue={
          hasEmptyValue ? meta.initialValue : Number(meta.initialValue)
        }
        onValueChange={(value) => helpers.setValue(value.value)}
        id={name}
        value={hasEmptyValue ? value : Number(value)}
        error={error}
        darkMode={darkMode}
        outline={outline}
        {...props}
      />
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </Styled.Wrapper>
  )
}

export default NumberFormatField
