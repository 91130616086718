import styled, { css } from 'styled-components'
import NumberFormat from 'react-number-format'
import { Colors, Typography } from 'styles'

export const Wrapper = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  ${(props) => props.fullWidth && `width: 100%`}
`

export const LabelContainer = styled.div<{ darkMode?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => (props.darkMode ? '8px' : '0')};
`

export const StyledLabel = styled.label.attrs(
  (props: { darkMode?: boolean; borderless?: boolean }) => props,
)`
  font-weight: ${(props) => (props.darkMode ? '300' : 'bold')};
  font-size: ${(props) => (props.darkMode ? '1rem' : '22px')};
  color: ${(props) => (props.darkMode ? Colors.GREY[400] : 'inherit')};
  margin-right: 8px;
  font-family: Inter;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
`

interface StyledNumberFormatProps {
  darkMode?: boolean
  error?: boolean
  outline?: boolean
}

export const StyledNumberFormat = styled(NumberFormat)<StyledNumberFormatProps>`
  font-family: Inter;
  font-size: 18px;
  padding: ${(props) => (props.darkMode ? '12px 18px' : '18.5px 14px')};
  border-radius: ${(props) => (props.darkMode ? '24px' : '4px')} !important;
  border: 1px solid ${(props) => (props.error ? '#f44336' : 'black')};

  &:focus {
    ${(props) => props.error && 'border-color: #f44336;'};
  }

  ${({ outline }) =>
    outline
      ? css`
          &:focus-visible {
            outline: none;
          }
        `
      : ''}
`
