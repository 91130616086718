import styled from 'styled-components'
import { Colors, Typography } from '../../styles'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;
`

export const ProgressBarContainer = styled.div`
  width: calc(100% - 8px);
  height: 24px;
  border: 1px solid ${Colors.GREY[400]};
  border-radius: 100px;
  overflow: hidden;
  box-sizing: border-box;

  box-shadow: 3px 3px 1px 0px ${Colors.GREY[400]},
    5px 5px 0px 1px ${Colors.GREY[400]};
  padding: 4px 6px;
`

export const ProgressBar = styled.div<{ progress: number }>`
  width: ${(props) => props.progress}%;
  height: 100%;
  background-color: ${Colors.PURPLE[400]};
  box-sizing: border-box;
  border-radius: 100px;
  transition: width 0.6s;
`

export const Text = styled.p`
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: ${Typography.X_SMALL};
  color: ${Colors.GREY[400]};
  font-family: Inter;
  margin-top: 24px;
  margin-bottom: 0;
`
