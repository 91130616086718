import { Dimensions, PixelRatio } from 'react-native-web'
import { isMobile } from 'react-device-detect'

import checkDeviceEqualsOrLargestThanIphoneX from './isEqualsOrLargestThanIphoneX'

const dimentions = Dimensions.get('window')
const width = isMobile ? dimentions.width : dimentions.height * 0.4
const height = isMobile ? dimentions.height : dimentions.height * 0.8

// DP -> density-independent pixels
export const widthPercentageToDP = (
  widthPercentage: string | number,
): number => {
  const percentageDesired =
    typeof widthPercentage === 'number'
      ? widthPercentage
      : parseFloat(widthPercentage)
  return PixelRatio.roundToNearestPixel((width * percentageDesired) / 100)
}

// DP -> density-independent pixels
export const heightPercentageToDP = (
  heightPercentage: string | number,
): number => {
  const percentageDesired =
    typeof heightPercentage === 'number'
      ? heightPercentage
      : parseFloat(heightPercentage)
  return PixelRatio.roundToNearestPixel((height * percentageDesired) / 100)
}

export const scaleFont = (size: number): number =>
  size * PixelRatio.getFontScale()

const WINDOW_WIDTH = Dimensions.get('window').width
const guidelineBaseWidth = 375

export const scaleSize = (size: number): number =>
  (WINDOW_WIDTH / guidelineBaseWidth) * size

export const isEqualsOrLargestThanIphoneX =
  checkDeviceEqualsOrLargestThanIphoneX()
export const extraSmallSize = widthPercentageToDP('1%')
export const smallSize = widthPercentageToDP('2%')
export const mediumSize = widthPercentageToDP('3%')
export const largeSize = widthPercentageToDP('4%')
export const extraLargeSize = widthPercentageToDP('5%')

export { width, height }

// ref: https://github.com/marudy/react-native-responsive-screen
