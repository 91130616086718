import { Duration } from './domain/Duration'

const getAudioDuration = async (file: File): Promise<Duration> => {
  return new Promise<Duration>((resolve, reject) => {
    const audio = new Audio()
    audio.src = URL.createObjectURL(file)

    audio.onloadedmetadata = () => {
      const durationOrError = Duration.create(audio.duration)
      if (!durationOrError.isSuccess) {
        reject('Error getting audio duration')
        return
      }

      resolve(durationOrError.getValue())
      URL.revokeObjectURL(audio.src)
    }

    audio.onerror = () => {
      reject('Error loading audio')
    }
  })
}

export default getAudioDuration
