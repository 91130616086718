import { FFmpeg } from '@ffmpeg/ffmpeg'
import { toBlobURL } from '@ffmpeg/util'
import mediaInfoFactory, { AudioTrack, Track } from 'mediainfo.js'
import { useCallback, useRef } from 'react'

const FFMPEG_LIB_BASE_URL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd'
/** bit rate: 96k, sample rate: 44100Hz */
const FFMPEG_COMPRESSION_ARGS = '-c:a libmp3lame -b:a 96k -ar 44100'
const MAX_SAMPLE_RATE = 44100
const MAX_BIT_RATE = 96000
const ALLOWED_FORMAT = 'MPEG Audio'

const progressPercentages = {
  mediaInfoLoad: 5,
  mediaInfoAnalyze: 5,
  ffmpegLoad: 5,
  ffmpegWriteFile: 5,
  ffmpegExec: 75,
  ffmpegReadFile: 5,
}

function isAudioTrack(track: Track): track is AudioTrack {
  return track['@type'] === 'Audio'
}

interface CompressAudioResult {
  compressedFile: File
  wasCompressed: boolean
}

interface UseAudioCompressionState {
  compressAudio: (
    file: File,
    onProgress?: (progress: number) => void,
    abortSignal?: AbortSignal,
  ) => Promise<CompressAudioResult>
}

const useAudioCompression = (): UseAudioCompressionState => {
  const ffmpegRef = useRef(new FFmpeg())

  const compressAudio = useCallback(
    async (
      file: File,
      onProgress?: (progress: number) => void,
      abortSignal?: AbortSignal,
    ): Promise<CompressAudioResult> => {
      let totalProgress = 0

      const handleProgress = (progress: number) => {
        if (abortSignal?.aborted) {
          throw new Error('O processamento do áudio foi cancelado')
        }
        onProgress?.(progress)
      }

      const mediaInfo = await mediaInfoFactory({
        locateFile: (path, prefix) => {
          const url = new URL(prefix)
          return `${url.origin}/${path}`
        },
      })
      handleProgress(totalProgress + progressPercentages.mediaInfoLoad)
      totalProgress += progressPercentages.mediaInfoLoad

      async function readChunk(chunkSize: number, offset: number) {
        const buffer = await file
          .slice(offset, offset + chunkSize)
          .arrayBuffer()
        return new Uint8Array(buffer)
      }
      const analyzedFile = await mediaInfo.analyzeData(file.size, readChunk)
      mediaInfo.close()

      handleProgress(totalProgress + progressPercentages.mediaInfoAnalyze)
      totalProgress += progressPercentages.mediaInfoAnalyze

      const audioInfo = analyzedFile.media.track.find(isAudioTrack)
      if (!audioInfo) {
        throw new Error('Não foi possível identificar o arquivo de áudio')
      }

      const shouldCompress =
        audioInfo.BitRate > MAX_BIT_RATE ||
        audioInfo.SamplingRate > MAX_SAMPLE_RATE ||
        audioInfo.Format !== ALLOWED_FORMAT

      if (!shouldCompress) {
        handleProgress(100)
        return { compressedFile: file, wasCompressed: false }
      }

      const ffmpeg = ffmpegRef.current

      const progressEventHandler = ({ progress }) => {
        handleProgress(
          totalProgress + progressPercentages.ffmpegExec * progress,
        )
      }
      ffmpeg.on('progress', progressEventHandler)

      abortSignal?.addEventListener('abort', () => {
        ffmpeg.off('progress', progressEventHandler)
        ffmpeg.terminate()
      })

      if (!ffmpeg.loaded) {
        await ffmpeg.load({
          coreURL: await toBlobURL(
            `${FFMPEG_LIB_BASE_URL}/ffmpeg-core.js`,
            'text/javascript',
          ),
          wasmURL: await toBlobURL(
            `${FFMPEG_LIB_BASE_URL}/ffmpeg-core.wasm`,
            'application/wasm',
          ),
        })
      }
      handleProgress(totalProgress + progressPercentages.ffmpegLoad)
      totalProgress += progressPercentages.ffmpegLoad

      const audioBuffer = await file.arrayBuffer()
      await ffmpeg.writeFile(file.name, new Uint8Array(audioBuffer))
      handleProgress(totalProgress + progressPercentages.ffmpegWriteFile)
      totalProgress += progressPercentages.ffmpegWriteFile

      const outputFileName = 'output.mp3'
      await ffmpeg.exec(
        [
          '-i',
          file.name,
          ...FFMPEG_COMPRESSION_ARGS.split(' '),
          outputFileName,
        ],
        undefined,
        { signal: abortSignal },
      )
      handleProgress(totalProgress + progressPercentages.ffmpegExec)
      totalProgress += progressPercentages.ffmpegExec

      const outputFile = await ffmpeg.readFile(outputFileName)
      handleProgress(totalProgress + progressPercentages.ffmpegReadFile)
      totalProgress += progressPercentages.ffmpegReadFile

      const compressedFile = new File([outputFile], file.name, {
        type: 'audio/mpeg',
      })
      return { compressedFile, wasCompressed: true }
    },
    [],
  )

  return { compressAudio }
}

export default useAudioCompression
