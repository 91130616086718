import { Snackbar } from '@material-ui/core'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Duration } from '../../../utils/domain/Duration'
import * as Styled from './AudioField.styled'
import { useField } from 'formik'

interface AudioFieldProps {
  name: string
  onChangeFile: (
    file: File,
    onProgress?: (progress: number) => void,
    abortSignal?: AbortSignal,
  ) => Promise<{ success: boolean } | undefined>
  loadingText?: string
}

const MAX_SIZE_PER_MINUTE = 2000000 // 2 MB
const MINIMUM_FILE_SIZE = 15000000 // 15 MB

const AudioField: React.FC<AudioFieldProps> = ({
  name,
  onChangeFile,
  loadingText,
}) => {
  const [, meta, helpers] = useField(name)
  const abortControllerRef = useRef<AbortController>(new AbortController())
  const audioInputRef = useRef<HTMLInputElement>(null)
  const [beforeUploadFile, setBeforeUploadFile] = useState<File>()
  const [audioUrl, setAudioUrl] = useState<string | undefined>(undefined)
  const [progress, setProgress] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDraggingFileOver, setIsDraggingFileOver] = useState<boolean>(false)
  const [fileName, setFileName] = useState<string | null>(null)
  const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>()

  const handleProgress = useCallback((progress) => {
    const value = Math.min(99, progress)
    setProgress(value)
  }, [])

  const handleClearFile = useCallback(() => {
    setAudioUrl(undefined)
    setBeforeUploadFile(undefined)
    setProgress(0)
    setIsLoading(false)
    setFileName(null)
  }, [])

  const verifySizeAndHandleUpload = useCallback(
    async (event: any) => {
      setProgress(0)
      setIsLoading(true)
      setFileName(beforeUploadFile.name)

      const duration = Duration.create(event.target.duration)
      if (!duration.isSuccess) {
        setSnackbarMessage(
          'Ops! Não conseguimos verificar a duração desse audio. Tente novamente, por favor.',
        )
        handleClearFile()
        return
      }

      const durationInMinutes = duration.getValue().toSeconds() / 60
      const maxAllowedFileSize =
        durationInMinutes * MAX_SIZE_PER_MINUTE + MINIMUM_FILE_SIZE
      if (beforeUploadFile.size > maxAllowedFileSize) {
        setSnackbarMessage(
          `O arquivo é muito grande para a duração que ele possui. Duração (min): ${durationInMinutes.toPrecision(
            3,
          )}. Tamanho: ${(beforeUploadFile.size / 1000000).toPrecision(
            3,
          )} MB. Tamanho máximo ${
            MAX_SIZE_PER_MINUTE / 1000000
          } MB por minuto + 20 MB.`,
        )
        handleClearFile()
        return
      }

      const abortController = new AbortController()
      abortControllerRef.current = abortController
      const result = await onChangeFile(
        beforeUploadFile,
        handleProgress,
        abortController.signal,
      )

      if (!result) {
        return
      }

      if (!result.success) {
        setSnackbarMessage('Não foi possível processar o arquivo de áudio.')
        handleClearFile()
        return
      }

      setProgress(100)
      setIsLoading(false)
    },
    [beforeUploadFile, handleProgress, onChangeFile, handleClearFile],
  )

  const handleFile = useCallback(
    async (file: File) => {
      abortControllerRef.current.abort()
      setFileName(null)
      setBeforeUploadFile(undefined)
      helpers.setValue(undefined)

      if (!file.type.includes('audio')) {
        setSnackbarMessage('O arquivo precisa ser um áudio.')
        return
      }

      setBeforeUploadFile(file)
    },
    [helpers],
  )

  const handleChangeFile = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files[0]
      if (!file) return

      await handleFile(file)
    },
    [handleFile],
  )

  const handleDropFile = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      setIsDraggingFileOver(false)
      const file = event.dataTransfer.files[0]
      if (!file) return
      handleFile(file)
    },
    [handleFile],
  )

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
    },
    [],
  )

  const handleDragEnter = useCallback(() => {
    setIsDraggingFileOver(true)
  }, [])

  const handleDragLeave = useCallback(() => {
    setIsDraggingFileOver(false)
  }, [])

  const handleLoadAudioError = useCallback(() => {
    setSnackbarMessage('Não foi possível carregar o arquivo de áudio.')
  }, [])

  useEffect(() => {
    if (!beforeUploadFile) return
    setAudioUrl(URL.createObjectURL(beforeUploadFile))
  }, [beforeUploadFile])

  const onSelectFile = useCallback(() => {
    audioInputRef.current?.click()
  }, [])

  return (
    <Styled.Wrapper>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={15000}
        onClose={() => setSnackbarMessage(undefined)}
        open={!!snackbarMessage}
        message={snackbarMessage}
      />

      <Styled.InputFileWrapper
        onClick={onSelectFile}
        onDrop={handleDropFile}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        isDraggingFileOver={isDraggingFileOver}
      >
        {!isLoading && fileName ? (
          <>
            <Styled.Text wordBreak="break-all">{fileName}</Styled.Text>
            <Styled.SuccessIcon />
            <Styled.Text>Processamento concluído!</Styled.Text>
          </>
        ) : isLoading ? (
          <>
            <Styled.Text wordBreak="break-all">{fileName}</Styled.Text>
            <Styled.ProgressBarWrapper>
              <ProgressBar progress={progress} text={loadingText} />
            </Styled.ProgressBarWrapper>
          </>
        ) : (
          <>
            <Styled.UploadIcon />
            <Styled.Text>
              Arraste e solte o arquivo aqui ou{' '}
              <Styled.TextEmphasis>selecione o arquivo</Styled.TextEmphasis>
            </Styled.Text>
          </>
        )}
      </Styled.InputFileWrapper>
      {meta.error && (
        <Styled.ErrorMessage>
          {isLoading ? 'Aguarde o processamento do seu episódio.' : meta.error}
        </Styled.ErrorMessage>
      )}

      <audio
        controls
        hidden
        onLoadedMetadata={verifySizeAndHandleUpload}
        onError={handleLoadAudioError}
        src={audioUrl}
      />
      <input
        type="file"
        hidden
        accept="audio/*,.wav,.aif,.aiff,.flac,.alac,.aac,.ogg,.mp3"
        ref={audioInputRef}
        onChange={handleChangeFile}
      />
    </Styled.Wrapper>
  )
}

export default AudioField
