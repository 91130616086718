export const isIosMobile = (): boolean => {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent)
}

export const isOtherMobile = (): boolean => {
  return /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  )
}

export const isWebView = (): boolean => {
  const isWebViewOnIos = isIosMobile() && !/Safari/i.test(navigator.userAgent)
  const isWebViewOnOtherMobile =
    isOtherMobile() && /(wv|WebView)/i.test(navigator.userAgent)
  return isWebViewOnIos || isWebViewOnOtherMobile
}
