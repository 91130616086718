import React, { useCallback, useRef, useState } from 'react'

import { TextField } from '@material-ui/core'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'

const DEFAULT_BUTTON_TEXT = 'Copiar Link'
const BUTTON_TEXT_TIMEOUT = 2000

const GoogleAuthWebViewWarning: React.FC = () => {
  const [buttonText, setButtonText] = useState(DEFAULT_BUTTON_TEXT)
  const buttonTextTimeoutRef = useRef<NodeJS.Timeout>()
  const inputUrlRef = useRef<HTMLInputElement>(null)

  const handleCopyLink = useCallback(async () => {
    clearTimeout(buttonTextTimeoutRef.current)
    inputUrlRef.current?.select()
    const success = document.execCommand('copy')
    setButtonText(success ? 'Link Copiado!' : 'Erro ao copiar link')
    buttonTextTimeoutRef.current = setTimeout(
      () => setButtonText(DEFAULT_BUTTON_TEXT),
      BUTTON_TEXT_TIMEOUT,
    )
  }, [])

  return (
    <>
      <p>
        Para fazer login pelo google, por favor, abra o site no seu navegador.
      </p>
      <TextField
        type="text"
        value={window.location.href}
        onChange={() => {}}
        variant="outlined"
        fullWidth
        inputRef={inputUrlRef}
      />
      <ButtonShadow
        variant="secondary"
        onPress={handleCopyLink}
        label={buttonText}
      />
    </>
  )
}

export default GoogleAuthWebViewWarning
