import styled from 'styled-components'
import {
  Button as MuiButton,
  Avatar,
  Grow,
  PopperPlacementType,
  MenuItem,
  MenuList,
  Drawer as MuiDrawer,
} from '@material-ui/core'
import MuiMenuIcon from '@material-ui/icons/Menu'
import StyledNavLink from '../StyledNavLink'
import Button from '../Button/Button'
import { Colors } from 'styles'
import { ReactComponent as LogoOrelo } from '../../assets/images/home/logoWithName.svg'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'

export const Header = styled.header`
  top: 0;
  z-index: 1000 !important;
`
interface HeaderStylesInterface {
  isDark?: boolean
  isLogged?: boolean
}
export const HeaderWrapper = styled.div.attrs(
  (props: HeaderStylesInterface) => props,
)`
  padding: 12px 20px 12px 0;
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  ${({ isLogged }) =>
    !isLogged && `border-bottom: 1.5px solid ${Colors.GREY[400]}`};
`

export const LogoAndSearchBarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 60px;
`

export const Logo = styled(LogoOrelo)`
  object-fit: scale-down;
  margin-right: 10px;
  height: 38px;

  @media (max-width: 767px) {
    width: auto;
    height: 32px;
  }
`

export const MobileMenuWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const MobileLogo = styled(Logo)`
  margin-left: 4px;
`

export const ProfileButton = styled(MuiButton)`
  max-height: 30px;

  &.MuiButton-root {
    font-size: 16px;
    text-transform: none;
    font-weight: bold;
    color: ${Colors.GREY[400]};
  }

  @media (max-width: 600px) {
    height: 32px;
  }
`

export const MenuIcon = styled(MuiMenuIcon)`
  color: black;
`

export const Drawer = styled(MuiDrawer)`
  .MuiPaper-root {
    background-color: ${Colors.GREY[400]};
  }
`

export const DrawerContent = styled.div`
  margin-top: 16px;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`

export const UserName = styled.div`
  justify-content: flex-start;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Colors.GREY[400]};
`

export const LogInButton = styled(MuiButton)`
  max-height: 30px;

  &.MuiButton-root {
    font-size: 16px;
    text-transform: none;
    font-weight: bold;
    font-family: 'Gellix';
    color: ${Colors.GREY[400]};
  }
`

export const HeaderMenuItem = styled(MenuItem)`
  &.MuiListItem-root {
    font-weight: bold;
    display: flex;
  }
`

export const HeaderMenuList = styled(MenuList)`
  &.MuiList-root {
    max-width: 170px;
  }
`

export const HeaderAvatar = styled(Avatar)`
  margin-left: 5px;
  max-width: 20px;
  max-height: 20px;
`

export const MenuAvatar = styled(Avatar)`
  margin-right: 4px;
  max-width: 20px;
  max-height: 20px;
`

export const HeaderGrow = styled(Grow)<{ placement: PopperPlacementType }>`
  transform-origin: ${(props) =>
    props.placement === 'bottom' ? 'center top' : 'center bottom'};
`

export const LinkButton = styled.button<{ isDark?: boolean }>`
  font-family: PP-Neue-Machina;
  text-transform: uppercase;
  background: none;
  border: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 27px;
  cursor: pointer;
  color: ${({ isDark }) => (isDark ? Colors.WHITE : Colors.GREY[400])};
`

export const StartProjectButton = styled(ButtonShadow)`
  &.MuiButton-root {
    justify-content: flex-start;
    text-align: center;
    font-size: 14px;
    font-weight: 800;
    line-height: 27px;
    color: white;
    @media (max-width: 1024px) {
      display: none;
    }
  }
`

export const HeaderLink = styled.a`
  text-decoration: none;

  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  margin-right: 24px;
`

export const NavLinkWithMargin = styled(StyledNavLink)``

export const Whitespace = styled.div`
  height: 5px;
`
export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 60%;
  justify-content: space-evenly;
`
