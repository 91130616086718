import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import BasePage from '../../components/BasePage/BasePage'
import * as Styled from './CreatorProfilePage.styled'
import { TitleAndLinkWrapper } from './CreatorProfilePage.styled'
import useFetch from '../../hooks/useFetch'
import { PodcastDTO, ReceivingInfoDTO } from '../../types'
import {
  getPodcastDetails,
  getPodcastReceivingInfo,
  getPodcastsCategories,
  getPodcastsContentTypes,
  updatePodcastForumSettings,
  updatePodcastInfo,
} from '../../services/api'
import Button from '../../components/Button/Button'
import {
  MessageWrapper,
  PageWrapper,
} from '../podcastSupport/PodcastSupport.styled'
import { Checkbox, ListItemText, MenuItem, Snackbar } from '@material-ui/core'
import { useTypedSelector } from '../../reducers'
import { ProfileType } from '../../reducers/profiles'
import TextFieldField from '../../components/Fields/TextFieldField/TextFieldField'
import { Formik } from 'formik'
import { Colors } from '../../styles'
import * as Yup from 'yup'
import SelectField from 'components/Fields/SelectField/SelectField'
import TabPanel from '../podcast/TabPanel'
import Recipient from 'components/Recipient/Recipient'

interface EditPodcastSupportPageProps {
  signOut: () => Promise<void>
}

interface PodcastInfoFormValues {
  description: string
  contentTypes: string[]
  categories: string[]
}

interface ForumInfoFormValues {
  forumVisibility: 'open' | 'followers' | 'supporters'
  forumTopicCreation: 'podcastTeam' | 'followers' | 'supporters'
}

const podcastInfoValidationSchema = Yup.object().shape({
  description: Yup.string().required('Campo obrigatório'),
})

const editForumInfoValidationSchema = Yup.object().shape({
  forumVisibility: Yup.string().required('Campo obrigatório'),
  forumTopicCreation: Yup.string().required('Campo obrigatório'),
})

const CreatorProfilePage: React.FC<EditPodcastSupportPageProps> = ({
  signOut,
}) => {
  const { podcastId } = useParams<{ podcastId: string }>()

  const [snackbarMessage, setSnackbarMessage] = useState<string>()
  const [currentTab, setCurrentTab] = useState('recipient')

  const profile = useTypedSelector<ProfileType>(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  const [podcastInfoInitialValues, setPodcastInfoInitialValues] = useState({
    description: '',
    contentTypes: [],
    categories: [],
  })

  const {
    isLoading: isLoadingPodcastDetails,
    data: podcastDetails,
    error: errorPodcastDetails,
    fetchData: fetchPodcastDetails,
  } = useFetch<PodcastDTO>(
    useCallback(() => {
      return getPodcastDetails(podcastId)
    }, [podcastId]),
  )

  const {
    isLoading: isLoadingReceivingInfo,
    data: receivingInfo,
    error: errorReceivingInfo,
    fetchData: fetchReceivingInfo,
  } = useFetch<ReceivingInfoDTO>(
    useCallback(() => {
      return getPodcastReceivingInfo(podcastId)
    }, [podcastId]),
  )

  const {
    isLoading: isLoadingPodcastsCategories,
    data: podcastsCategories,
    error: errorPodcastsCategories,
    fetchData: fetchPodcastsCategories,
  } = useFetch<string[]>(
    useCallback(() => {
      return getPodcastsCategories()
    }, []),
  )

  const {
    isLoading: isLoadingPodcastsContentTypes,
    data: podcastsContentTypes,
    error: errorPodcastsContentTypes,
    fetchData: fetchPodcastsContentTypes,
  } = useFetch<string[]>(
    useCallback(() => {
      return getPodcastsContentTypes()
    }, []),
  )

  useEffect(() => {
    if (podcastDetails) {
      setPodcastInfoInitialValues({
        description: podcastDetails.description ?? '',
        categories: podcastDetails.categories ?? [],
        contentTypes: podcastDetails.contentTypes ?? [],
      })
    }
  }, [podcastDetails, receivingInfo])

  const submitPodcastInfoForm = async (
    formValues: PodcastInfoFormValues,
  ): Promise<void> => {
    const body = {
      podcastInfo: {
        description: formValues.description,
        categories: formValues.categories,
        contentTypes: formValues.contentTypes,
      },
    }

    const res = await updatePodcastInfo(profile.id, body)
    if (res.error) {
      setSnackbarMessage(
        'Ocorreu um erro inesperado. Tente novamente ou entre em contato com o suporte',
      )
    } else {
      setSnackbarMessage('Informações atualizadas com sucesso!')
    }
  }

  const submitForumInfoForm = async (
    formValues: ForumInfoFormValues,
  ): Promise<void> => {
    const body = {
      forumVisibility: formValues.forumVisibility,
      forumTopicCreation: formValues.forumTopicCreation,
    }

    const res = await updatePodcastForumSettings(podcastId, body)
    if (res.error) {
      setSnackbarMessage(
        'Ocorreu um erro inesperado. Tente novamente ou entre em contato com o suporte',
      )
    } else {
      setSnackbarMessage('Informações atualizadas com sucesso!')
    }
  }

  if (
    isLoadingPodcastDetails ||
    isLoadingPodcastsCategories ||
    isLoadingPodcastsContentTypes ||
    isLoadingReceivingInfo
  ) {
    return (
      <BasePage signOut={signOut} showNavigationBar>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>Carregando...</Styled.MessageWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  if (
    errorPodcastDetails ||
    errorPodcastsCategories ||
    errorPodcastsContentTypes ||
    errorReceivingInfo
  ) {
    return (
      <BasePage signOut={signOut} isDark showNavigationBar>
        <PageWrapper>
          <MessageWrapper>Ops, parece que tivemos um erro aqui.</MessageWrapper>
          <Button
            variant="contained"
            fontColor="black"
            onClick={() =>
              fetchPodcastDetails() &&
              fetchPodcastsCategories() &&
              fetchPodcastsContentTypes() &&
              fetchReceivingInfo()
            }
          >
            Tentar novamente
          </Button>
        </PageWrapper>
      </BasePage>
    )
  }

  const onSeeTerms = () => {
    window.open('https://orelo.audio/legal/tcuser', '_blank')
  }

  const onSeePrivate = () => {
    window.open('https://orelo.audio/legal/pp', '_blank')
  }

  return (
    <BasePage signOut={signOut} showNavigationBar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!snackbarMessage}
        autoHideDuration={15000}
        onClose={() => setSnackbarMessage(undefined)}
        message={snackbarMessage}
      />

      <Styled.PageWrapper>
        <TitleAndLinkWrapper>Perfil</TitleAndLinkWrapper>

        <Styled.LeftWrapper>
          <Styled.MainWrapper>
            <Styled.AvatarWrapper>
              <Styled.PictureWrapper>
                <Styled.MenuAvatar src={profile.avatar} />
              </Styled.PictureWrapper>

              <Styled.TitleWrapper>
                <Styled.CreatorTitle>{profile.name}</Styled.CreatorTitle>
                <Styled.CreatorLink>
                  orelo.cc/{podcastDetails.pathName}
                </Styled.CreatorLink>
              </Styled.TitleWrapper>
            </Styled.AvatarWrapper>

            <Formik
              validationSchema={podcastInfoValidationSchema}
              initialValues={podcastInfoInitialValues}
              onSubmit={async (values) => {
                await submitPodcastInfoForm(values)
              }}
              enableReinitialize
            >
              {({ handleSubmit, values, touched, errors }) => {
                return (
                  <Styled.FlexForm>
                    <TextFieldField
                      name="description"
                      variant="outlined"
                      label="Descrição"
                      value={values.description}
                      error={!!(touched.description && errors?.description)}
                      helperText={touched.description && errors?.description}
                      fullWidth
                      multiline
                      darkMode
                    />

                    <Styled.Row>
                      <Styled.Column flex="0 0 49%">
                        <SelectField
                          name="contentTypes"
                          label="Selecione o tipo de conteúdo"
                          variant="outlined"
                          displayEmpty
                          multiple
                          useFormControl
                          darkMode
                          style={{
                            backgroundColor: 'white',
                            borderRadius: '2rem',
                            margin: '16px 0 8px 0',
                            color: '#000000',
                          }}
                          error={
                            !!(touched.contentTypes && errors?.contentTypes)
                          }
                          helperText={
                            touched.contentTypes &&
                            errors?.contentTypes?.toString()
                          }
                        >
                          {podcastsContentTypes.map((type) => (
                            <MenuItem value={type}>
                              <Checkbox
                                checked={values.contentTypes.indexOf(type) > -1}
                              />
                              <ListItemText primary={type} />
                            </MenuItem>
                          ))}
                        </SelectField>
                      </Styled.Column>

                      <Styled.Column flex="0 0 49%">
                        <SelectField
                          name="categories"
                          label="Selecione sua categoria"
                          variant="outlined"
                          displayEmpty
                          multiple
                          useFormControl
                          darkMode
                          style={{
                            backgroundColor: 'white',
                            borderRadius: '2rem',
                            margin: '16px 0 8px 0',
                            color: '#000000',
                          }}
                          error={!!(touched.categories && errors?.categories)}
                          helperText={
                            touched.categories && errors?.categories?.toString()
                          }
                        >
                          {podcastsCategories.map((category) => (
                            <MenuItem value={category}>
                              <Checkbox
                                checked={
                                  values.categories.indexOf(category) > -1
                                }
                              />
                              <ListItemText primary={category} />
                            </MenuItem>
                          ))}
                        </SelectField>
                      </Styled.Column>
                    </Styled.Row>

                    <Button
                      variant="contained"
                      size="large"
                      fontSize="large"
                      buttonColorOnHover={Colors.TEXT_DARK}
                      buttonColor={Colors.BRAND_PRIMARY}
                      fontColor={Colors.TEXT_LIGHTEN}
                      onClick={() => handleSubmit()}
                    >
                      Salvar
                    </Button>
                  </Styled.FlexForm>
                )
              }}
            </Formik>
          </Styled.MainWrapper>
        </Styled.LeftWrapper>

        <Styled.RightWrapper>
          <Styled.TabWrapper>
            <Styled.StyledTabs
              value={currentTab}
              textColor="inherit"
              TabIndicatorProps={{
                style: {
                  background: '#000',
                },
              }}
              onChange={(_event, value) => {
                setCurrentTab(value)
              }}
              variant="scrollable"
            >
              <Styled.StyledTab label="Recebedor" value={'recipient'} />
              <Styled.StyledTab label="Opções" value={'options'} />
            </Styled.StyledTabs>

            <TabPanel value={'recipient'} index={currentTab}>
              <Recipient podcastId={podcastId} />
            </TabPanel>

            <TabPanel value={'options'} index={currentTab}>
              <Formik
                validationSchema={editForumInfoValidationSchema}
                initialValues={{
                  forumVisibility: podcastDetails.forumVisibility,
                  forumTopicCreation: podcastDetails.forumTopicCreation,
                }}
                onSubmit={async (values) => {
                  if (!values.forumVisibility || !values.forumTopicCreation) {
                    setSnackbarMessage(
                      'Ocorreu um erro inesperado. Tente novamente ou entre em contato com o suporte',
                    )
                  } else {
                    await submitForumInfoForm(values)
                  }
                }}
                enableReinitialize
              >
                {({ handleSubmit, values, touched, errors }) => {
                  return (
                    <Styled.FlexForm>
                      <SelectField
                        name="forumTopicCreation"
                        label="Quem pode iniciar tópicos no mural?"
                        variant="outlined"
                        displayEmpty
                        darkMode
                        fullWidth
                        error={
                          !!(
                            touched.forumTopicCreation &&
                            errors?.forumTopicCreation
                          )
                        }
                        helperText={
                          touched.forumTopicCreation &&
                          errors?.forumTopicCreation
                        }
                        value={values.forumTopicCreation}
                      >
                        <MenuItem value={'podcastTeam'}>
                          Apenas o perfil do podcast
                        </MenuItem>
                        <MenuItem value={'supporters'}>
                          Exclusivo para apoiadores
                        </MenuItem>
                        <MenuItem value={'followers'}>
                          Todos os seguidores
                        </MenuItem>
                      </SelectField>

                      <SelectField
                        name="forumVisibility"
                        label="Quem pode responder tópicos no mural?"
                        variant="outlined"
                        displayEmpty
                        darkMode
                        fullWidth
                        error={
                          !!(touched.forumVisibility && errors?.forumVisibility)
                        }
                        helperText={
                          touched.forumVisibility && errors?.forumVisibility
                        }
                        value={values.forumVisibility}
                      >
                        <MenuItem value={'podcastTeam'}>
                          Apenas o perfil do podcast
                        </MenuItem>
                        <MenuItem value={'supporters'}>
                          Exclusivo para apoiadores
                        </MenuItem>
                        <MenuItem value={'followers'}>
                          Todos os seguidores
                        </MenuItem>
                      </SelectField>

                      <Button
                        variant="contained"
                        size="large"
                        fontSize="large"
                        buttonColorOnHover={Colors.TEXT_DARK}
                        buttonColor={Colors.BRAND_PRIMARY}
                        fontColor={Colors.TEXT_LIGHTEN}
                        onClick={() => {
                          handleSubmit()
                        }}
                        style={{ margin: '32px 0 0 auto' }}
                      >
                        Salvar
                      </Button>
                    </Styled.FlexForm>
                  )
                }}
              </Formik>
            </TabPanel>
          </Styled.TabWrapper>

          <Styled.FooterWrapper>
            <Styled.FooterButtons>
              <Button
                variant="contained"
                size="small"
                fontSize="small"
                buttonColor={Colors.TEXT_LIGHTEN}
                fontColor={Colors.BRAND_PRIMARY}
                borderColor={Colors.BRAND_PRIMARY}
                onClick={() => onSeeTerms()}
              >
                Ver Termos e Condições
              </Button>

              <Button
                variant="contained"
                size="small"
                fontSize="small"
                buttonColor={Colors.TEXT_LIGHTEN}
                fontColor={Colors.BRAND_PRIMARY}
                borderColor={Colors.BRAND_PRIMARY}
                onClick={() => onSeePrivate()}
              >
                Ver Política de Privacidade
              </Button>
            </Styled.FooterButtons>

            <Styled.FooterInfo
              href="https://orelohelp.zendesk.com/hc/pt-br"
              target="_blank"
            >
              <Styled.InfoIcon /> Para mais informações ou configurações do seu
              projeto, clique aqui.
            </Styled.FooterInfo>
          </Styled.FooterWrapper>
        </Styled.RightWrapper>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default CreatorProfilePage
