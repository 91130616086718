import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'
import Button from '../../components/Button/Button'
import { Form } from 'formik'
import MicIcon from '@material-ui/icons/Mic'
import { Colors } from 'styles'

export const PageWrapper = styled.div`
  padding: 80px 64px;
  color: ${Colors.GREY[400]};

  @media (max-width: 600px) {
    padding: 16px;
  }
`

export const TitleAndLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 42px;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 600px) {
    align-items: center;
    width: 100%;
  }
`

export const MenuWrapper = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
  margin-bottom: 42px;
  width: 30%;

  background: #000000;
  border-radius: 24px;
  padding: 24px 32px;
  margin-top: 4rem;
  gap: 12px;

  @media (max-width: 600px) {
    align-items: center;
  }
`

export const MenuHeader = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;

  text-align: right;
  text-transform: uppercase;

  color: #ffffff;
`

export const ItemGrid = styled.div`
  display: flex;
  gap: 12px;
  padding: 10px;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 600px) {
    padding: 0;
  }
`

export const ItemTitle = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
`

export const ItemReleaseDate = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  display: inline;
  margin-right: 2rem;
`

export const SeeMoreButton = styled(Button)`
  &.MuiButton-root {
    margin-top: 24px;
    display: block;
    background-color: #000;

    &:hover {
      color: #000;
    }
  }
`

export const ItemActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

export const ItemBody = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  padding: 32px 32px 48px 32px;
  word-break: break-word;
`

export const ButtonWrapper = styled.div`
  justify-content: end;
  width: 100%;
  display: flex;

  @media (max-width: 600px) {
    justify-content: center;
  }
`

export const PageTitle = styled.div`
  font-family: PP-Neue-Machina;
  font-weight: 700;
  font-size: 50px;
  line-height: 80px;
  margin-top: 16px;
  width: 100%;

  @media (max-width: 600px) {
    text-align: center;
    font-size: 2.5rem;
    line-height: normal;
    margin-bottom: 32px;
  }
`

export const MessageWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const LoadingCircle = styled(CircularProgress)`
  margin-left: 12px;
`

export const SupportTierCardWrapper = styled.div`
  width: 40%;
  margin-bottom: 16px;

  &.MuiCard-root {
    background-color: #1f1f1f;
    color: white;
  }

  @media (max-width: 600px) {
    width: 90%;
  }
`

export const TiersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

export const FlexForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`

export const MicrophoneCircledIcon = styled(MicIcon)`
  background: black;
  color: #fff;
  border-radius: 100%;
  width: 1.6rem;
  height: 1.6rem;
  padding: 4px;
  margin-top: 12px;
  margin-left: 12px;
`
