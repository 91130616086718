//BRAND
export const BRAND_PRIMARY = '#000000'
export const BRAND_SECONDARY = '#FFFFFF'

//SUPPORT
export const SUPPORT_INFORMATION = '#7C46FF'
export const SUPPORT_ERROR = '#EE4930'
export const SUPPORT_ALERT = '#FFCB00'
export const SUPPORT_SUCCESS = '#0099FF'
export const SUPPORT_GOLDEN = '#C0AC43'

//SHADES
export const SHADE_WHITE = '#FFFFFF'
export const SHADE_LIGHTEST = '#C2E4FB'
export const SHADE_LIGHT = '#50B4FF'
export const SHADE_MEDIUM = '#DADADA'
export const SHADE_DARK_BLUE = '#005EA5'
export const SHADE_DARKEST = '#000000'
export const LIGHT_DARK = 'rgba(0, 0, 0, 0.4)'
export const LIGHT_WHITE = 'rgba(255, 255, 255, 0.6)'
export const MILK_WHITE = '#F2F2F2'
export const LIGHT_GREY = '#BDBDBD'

// OPACITY
export const OPACITY_SEMI_OPAQUE = 0.8
export const OPACITY_INTENSE = 0.64
export const OPACITY_MEDIUM = 0.4
export const OPACITY_SEMI_TRANSPARENT = 0.16
export const PROGRESSIVE_IMAGE_FOREGROUND = 'rgba(242, 242, 242, 0.5)'

// SOCIAL BUTTONS
export const FACEBOOK = '#4267B2'

// OTHERS
export const BLUE_ARMOR = '#DDD'
export const GRAY = '#2e2e2e'
export const LIGHTER_GRAY = '#636363'
export const BACKGROUND = '#F2F2F2'
export const LIGHT_SUCCESS = 'rgba(80, 180, 255, 0.5)'

// TEXT
export const TEXT_LIGHT = '#EFEFEF'
export const TEXT_LIGHTEN = '#FFF'

export const TEXT_DARK = '#1f1f1f'
export const TEST_DARKEN = '#000'

// rebranding
export const WHITE = '#FFFFFF'
export const BLACK = '#000000'
export const RED = '#FF0000'
export const GREY = {
  50: '#F8F8F8',
  100: '#F1F1F1',
  150: '#D0D0D0',
  200: '#848E98',
  400: '#212427',
}
export const PURPLE = {
  50: '#DFD9FF',
  400: '#6247E8',
}
