import React from 'react'
import * as Styled from './ProgressBar.styled'

interface ProgressBarProps {
  /** 0-100 range */
  progress: number
  text?: string
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress, text }) => {
  const progressValue = Math.min(100, Math.max(2, progress))

  return (
    <Styled.Wrapper>
      <Styled.ProgressBarContainer>
        <Styled.ProgressBar progress={progressValue} />
      </Styled.ProgressBarContainer>
      {text && <Styled.Text>{text}</Styled.Text>}
    </Styled.Wrapper>
  )
}

export default ProgressBar
