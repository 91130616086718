import React, { useEffect } from 'react'
import BasePage from '../../components/BasePage/BasePage'
import { useGoToRoute } from '../../Routes/RouteAux'
import * as Styled from './RegisterCreator.styled'
import { getCreatorProfilePath } from '../../Routes/RouteNames'
import { useTypedSelector } from '../../reducers'
import {
  changeProfile,
  ProfileType,
  refreshAvailableProfiles,
} from '../../reducers/profiles'
import { useDispatch } from 'react-redux'

interface RegisterCreatorOutroProps {
  signOut: () => Promise<void>
}

const RegisterCreatorOutro: React.FC<RegisterCreatorOutroProps> = ({
  signOut,
}) => {
  const goToRoute = useGoToRoute()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(refreshAvailableProfiles())
  }, [])

  const availableProfiles = useTypedSelector<ProfileType[]>(
    (state) => state && state.profiles && state.profiles.availableProfiles,
  )

  const currentProfile = useTypedSelector<ProfileType>(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  useEffect(() => {
    availableProfiles.find((profile) => {
      if (profile.profile === 'podcast') {
        dispatch(changeProfile(profile))
      }
      return profile.profile === 'podcast'
    })
  }, [availableProfiles])

  return (
    <BasePage signOut={signOut}>
      <Styled.PageWrapper>
        <Styled.TextPageWrapper>
          <Styled.PageSubTitle>sucesso!</Styled.PageSubTitle>
          <Styled.PageSubTitle>
            você já está registrado como um creator na orelo!
          </Styled.PageSubTitle>
          <Styled.PageSubTitle>
            agora, você já pode se cadastrar como recebedor para receber apoios,
            editar suas informações, configurar seus planos de apoio e publicar
            conteúdo direto com a gente.
          </Styled.PageSubTitle>
          <Styled.LargeButton
            variant="contained"
            buttonColorOnHover="#303030"
            buttonColor="#414141"
            onClick={() => goToRoute(getCreatorProfilePath(currentProfile.id))}
          >
            ir para o meu dashboard!
          </Styled.LargeButton>
        </Styled.TextPageWrapper>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default RegisterCreatorOutro
