import styled, { css } from 'styled-components'
import { Colors, Typography } from 'styles'
import { ReactComponent as UploadIconSvg } from '../../../assets/icons/upload2.svg'
import { ReactComponent as SuccessIconSvg } from '../../../assets/icons/successCircle.svg'

export const Wrapper = styled.div`
  width: 100%;
  margin: 12px 0;
`

interface InputFileWrapperProps {
  isDraggingFileOver?: boolean
}

export const InputFileWrapper = styled.div<InputFileWrapperProps>`
  border: 3px solid
    ${({ isDraggingFileOver }) =>
      isDraggingFileOver ? Colors.PURPLE[400] : Colors.BLACK};
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 10px;
  gap: 20px;
`

interface TextProps {
  wordBreak?: 'break-all'
}

export const Text = styled.p<TextProps>`
  font-family: Inter;
  font-size: 16px;
  color: ${Colors.GREY[400]};
  margin: 0px;
  pointer-events: none;
  text-align: center;
  ${({ wordBreak }) =>
    wordBreak
      ? css`
          word-break: ${wordBreak};
        `
      : ''}
`

export const TextEmphasis = styled.span`
  text-decoration: underline;
  font-weight: ${Typography.BOLD};
`

export const ProgressBarWrapper = styled.div`
  pointer-events: none;
  width: 60%;

  @media (max-width: 600px) {
    width: 90%;
  }
`

export const UploadIcon = styled(UploadIconSvg)`
  width: 63px;
  height: 60px;
  fill: ${Colors.PURPLE[400]};
  pointer-events: none;
`

export const SuccessIcon = styled(SuccessIconSvg)`
  width: 63px;
  height: 60px;
  fill: ${Colors.PURPLE[400]};
  pointer-events: none;
`

export const ErrorMessage = styled.p`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 12px;
  color: #f44336;
  margin: 0px;
  margin-top: 6px;
  pointer-events: none;
`
