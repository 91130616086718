import * as Colors from './../../styles/colors'
import styled from 'styled-components'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'
import Button from '../../components/Button/Button'

export const PageWrapper = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  margin: auto;
  align-items: center;
  color: ${Colors.TEXT_LIGHT};
  width: 50%;

  @media (max-width: 600px) {
    width: 95%;
  }
`

export const LogoWrapper = styled.div`
  height: 70px;
  width: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Logo = styled.img`
  height: 50px;
`

export const Text = styled.div`
  font-size: 1.3em;
  width: 100%;
  margin: 12px 0;
  text-align: center;
  font-family: PP-Neue-Machina;
  text-transform: uppercase;
  color: ${Colors.GREY[400]};

  @media (max-width: 600px) {
    text-align: center;
  }
`

export const MainText = styled(Text)`
  font-weight: 800;
`

export const ButtonContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px;
`

export const SocialButton = styled(ButtonShadow)`
  width: 300px;
  height: 45px;

  &.MuiButtonBase-root {
    margin: 12px 0;
  }

  @media (max-width: 600px) {
    &.MuiButton-root {
      font-size: 1em;
    }
  }
`

export const SimpleSocialButton = styled(Button)`
  width: 300px;
  height: 45px;

  &.MuiButtonBase-root {
    margin: 12px 0;
  }

  @media (max-width: 600px) {
    &.MuiButton-root {
      font-size: 1em;
    }
  }
`

export const SocialIcon = styled.img`
  height: 25px;
`
