import {
  Avatar,
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  Switch,
} from '@material-ui/core'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import styled from 'styled-components'
import Button from '../../components/Button/Button'
import { Colors } from '../../styles'

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  margin: auto;
  width: 60%;
  color: #e4e4e4;
`

export const MessageWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const PageTitle = styled.div`
  font-size: 60px;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 60px;
  margin-bottom: 40px;
  text-align: center;
`

export const CardsWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
`

export const CardsLine = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const Anchor = styled.a`
  color: white;
  margin-top: 20px;
  text-decoration: underline;

  &:focus,
  &:visited,
  &:active {
    text-decoration: none;
  }
`

export const Text = styled.div`
  margin: 24px 0;
`

export const CardItem = styled(Card)`
  width: 100%;

  &.MuiPaper-outlined {
    background-color: ${Colors.TEXT_DARK};
    color: #fff;
    .MuiCardContent-root {
      input,
      .MuiInputBase-multiline {
        background-color: ${Colors.TEXT_DARK};
        margin-top: 7px;
        border: 1px solid ${Colors.TEXT_LIGHT};
        color: ${Colors.TEXT_LIGHT};
      }
      textarea {
        color: ${Colors.TEXT_LIGHT};
      }
    }
  }

  &.MuiPaper-rounded {
    border-radius: 12px;
  }
`

export const CardTitleAndValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  b {
    color: #fff;
  }
`
export const FormControlWrapper = styled(FormControl)`
  flex: 1;
  margin-left: 24px !important;
`

export const TitleWrapper = styled.div`
  width: 40%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 600px) {
    align-items: center;
    width: 95%;
  }
`

export const PageSubTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  width: 100%;
  margin: 24px 0;
  text-align: center;

  @media (max-width: 600px) {
    text-align: center;
  }
`

export const LargeButton = styled(Button)`
  min-width: 250px;
  height: 32px;
  align-self: center;
  &.MuiButtonBase-root {
    margin: 24px 0;
  }
`

export const LoadingSpinner = styled(CircularProgress)`
  margin-right: 15px;
`

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
`

export const ActiveIcon = styled(CheckCircleOutlineIcon)`
  position: absolute;
  bottom: 22px;
  right: 21px;
`

export const InactiveIcon = styled(HighlightOffIcon)`
  position: absolute;
  bottom: 22px;
  right: 21px;
`

export const ProfileAvatar = styled(Avatar)`
  border-radius: 20px;
  margin-bottom: 18px;

  &.MuiAvatar-root {
    margin: auto;
    width: 160px;
    height: 160px;
  }
`

export const CardTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  width: 100%;
  padding: 16px;
`

export const NotificationsWrapper = styled.div`
  padding: 16px;
  padding-top: 0;
`

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const StyledLabel = styled(InputLabel)`
  margin-right: 12px;

  &.MuiFormLabel-root {
    font-family: 'Gellix';
    font-weight: normal;
    font-size: 18px;
    color: ${Colors.WHITE};
  }
`

export const StyledSwitch = styled(Switch)`
  & .MuiSwitch-track {
    background-color: ${Colors.LIGHTER_GRAY};
  }

  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${Colors.MILK_WHITE};
  }
`

export const StyledCircularProgress = styled(CircularProgress)`
  & .MuiCircularProgress-circle {
    color: ${Colors.MILK_WHITE};
  }
`
